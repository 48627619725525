import { useEnv } from "./useEnv";
import { computed } from "vue";

export function useUser() {
  const { isPending, isError, error, data } = useEnv();

  const user = computed(() => data.value?.utilisateurConnecte);

  return {
    user,
    isPending,
    isError,
    error,
  };
}
