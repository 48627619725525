import { ref } from "vue";

const stripTrailingSlash = (s: string): string =>
  s.endsWith("/") ? s.slice(0, -1) : s;

const baseUrl = ref("");

export function useBaseUrl() {
  const setBaseUrl = (url: string) => {
    baseUrl.value = stripTrailingSlash(url);
  };

  return {
    setBaseUrl,
    baseUrl,
  };
}
