<template>
  <div v-show="links">
    <sl-dropdown
      ref="dropdownMenu"
      placement="bottom-end"
      distance="10"
      skidding="-10"
      :disabled="!links"
      hoist
    >
      <Trigger slot="trigger">
        <PhLink weight="bold" size="1.2em" role="presentation" />
      </Trigger>
      <div
        class="pt-2 min-w-48 max-w-96 rounded-[--sl-border-radius-medium] bg-white max-h-[90vh] overflow-auto"
      >
        <a
          v-for="link in links"
          :key="link.id"
          :href="link.url"
          class="flex gap-2 hover:underline my-4 pl-4"
          target="_blank"
        >
          <img
            :src="`${baseUrl}/img/url/${link.image}`"
            class="w-6 h-6 rounded-full border"
          />
          <span>{{ link.titre }}</span>
        </a>
        <div
          class="bg-[--its-color-neutral-500] h-12 flex justify-center items-center"
          title="La gestion des liens n’est pas disponible sur l’agenda."
        >
          <span class="text-white">Gérer mes liens</span>
        </div>
      </div>
    </sl-dropdown>
  </div>
</template>

<script setup lang="ts">
import { useBaseUrl } from "@/composables/useBaseUrl";
import { useLinks } from "@/composables/useLinks";
import { PhLink } from "@phosphor-icons/vue";
import { SlDropdown } from "@shoelace-style/shoelace";
import Trigger from "./Trigger.vue";

const { links } = useLinks();
const { baseUrl } = useBaseUrl();
</script>
