import { useQuery } from "@tanstack/vue-query";
import { useCustomQueryClient } from "./useCustomQueryClient";
import { fetchEnv } from "@/api/env";
import { EnvironmentVariables } from "@/type/api";
import { computed, watch } from "vue";
import { useBaseUrl } from "./useBaseUrl";

const queryClient = useCustomQueryClient();
const { baseUrl } = useBaseUrl();

export function useEnv() {
  const enabled = computed(() => baseUrl.value?.length > 1);

  const fetchEnvUrl = computed(() =>
    enabled ? baseUrl.value + "/aide/getEnvironmentVariables" : "",
  );

  const { isPending, isError, data, error, refetch } =
    useQuery<EnvironmentVariables>(
      {
        queryKey: ["environment_variables"],
        queryFn: () => fetchEnv(fetchEnvUrl.value),
        retry: false,
        enabled,
      },
      queryClient,
    );

  watch(
    () => baseUrl,
    () => refetch(),
  );

  return {
    data,
    isPending,
    isError,
    error,
  };
}
