<template>
  <header class="flex justify-end items-center gap-x-6 px-5 py-0">
    <SearchBar />
    <div class="flex-1"></div>
    <!-- Spacer -->
    <Help />
    <Links />
    <!-- ICON NOUVEAUTE -->
    <!-- ICON NOTIF -->
    <Avatar />
  </header>
</template>

<script setup lang="ts">
import "@shoelace-style/shoelace/dist/components/dropdown/dropdown.js";
import "@shoelace-style/shoelace/dist/components/menu/menu.js";
import "@shoelace-style/shoelace/dist/components/menu-item/menu-item.js";
import "@shoelace-style/shoelace/dist/components/input/input.js";
import { onMounted, watch } from "vue";
import { useBaseUrl } from "@/composables/useBaseUrl";
import Avatar from "./Avatar.vue";
import Help from "./Help.vue";
import Links from "./Links.vue";
import SearchBar from "./SearchBar.vue";

const { setBaseUrl } = useBaseUrl();

const props = defineProps<{
  baseurl?: string;
}>();

watch(
  () => props.baseurl,
  () => {
    setBaseUrl(props.baseurl || "");
  },
);

onMounted(() => {
  setBaseUrl(props.baseurl || "");
});
</script>

<style lang="scss">
@use "@developpeur/core-lib-front/scss/base/mixins/colors.scss";
@use "@developpeur/core-lib-front/scss/shoelace/customization/avatar.scss";
@use "@developpeur/core-lib-front/scss/shoelace/customization/dropdown.scss";
@use "@developpeur/core-lib-front/scss/shoelace/customization/input.scss" as
  custom_input;
@use "@developpeur/core-lib-front/scss/shoelace/radius.scss";
@use "@developpeur/core-lib-front/scss/shoelace/spacing.scss";
@use "@developpeur/core-lib-front/scss/shoelace/shadow.scss";
@use "@developpeur/core-lib-front/scss/shoelace/panel.scss";
@use "@developpeur/core-lib-front/scss/shoelace/input.scss";
@use "@developpeur/core-lib-front/core-lib-front.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

sl-input::part(input)::placeholder {
  font-style: italic;
  color: var(--its-color-neutral-800);
}

sl-input::part(base) {
  padding-right: 0.5em;
}
</style>
