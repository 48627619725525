import { EnvironmentVariables } from "@/type/api";

const fetchEnv = (
  fetchEnvUrl: RequestInfo | URL,
): Promise<EnvironmentVariables> => {
  return fetch(fetchEnvUrl, {
    method: "GET",
    credentials: "include",
  }).then((data) => data.json());
};

export { fetchEnv };
