import { QueryClient } from "@tanstack/vue-query";

export function useCustomQueryClient() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 30 * 1000, // stale in 30 secondes
      },
    },
  });
  return queryClient;
}
