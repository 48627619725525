import { ItsUseTeambrain } from "@developpeur/core-lib-front";
import { useEnv } from "./useEnv";
import { computed, onMounted, ref, watch } from "vue";

const { setupTeamBrain } = ItsUseTeambrain();
const isInitialized = ref(false);

const initStyle = () => {
  const style = `
    :root {
      .tb-ae-iconBox {
        visibility: hidden;
      }

      .tb-ae-messagesContainer {
        visibility: hidden;
      }
    }
  `;
  const styleSheet = new CSSStyleSheet();
  styleSheet.replaceSync(style);
  document.adoptedStyleSheets = [...document.adoptedStyleSheets, styleSheet];
};

const activate = () => {
  const iframe = document.querySelector<HTMLIFrameElement>(
    ".tb-ae-iconContainer iframe",
  );
  if (!iframe) {
    console.error("Teambrain iframe is missing");
    return;
  }

  if (!iframe.contentWindow) {
    console.error("Teambrain content window is missing");
    return;
  }

  const icon =
    iframe.contentWindow.document.querySelector<HTMLElement>(
      ".tb-ae-icon-flex",
    );
  if (!icon) {
    console.error("Teambrain icon not found");
    return;
  }

  icon.click();
};

export function useTeamBrain() {
  const { data } = useEnv();

  const teamBrainUrl = computed(() => data.value?.["tb-url"]);

  watch(teamBrainUrl, (url) => {
    if (!isInitialized.value && (url?.length || 0) > 0) {
      setupTeamBrain(url);
      isInitialized.value = true;
    }
  });

  onMounted(() => {
    initStyle();
  });

  return {
    isInitialized,
    activate,
  };
}
