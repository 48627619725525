<template>
  <div v-show="helpMenu">
    <sl-dropdown
      ref="dropdownMenu"
      placement="bottom-end"
      distance="10"
      skidding="-10"
      :disabled="!helpMenu"
      hoist
    >
      <Trigger slot="trigger">
        <PhQuestion weight="bold" size="1.2em" role="presentation" />
      </Trigger>
      <div
        class="py-4 min-w-96 rounded-[--sl-border-radius-medium] bg-white max-h-[90vh] overflow-auto"
      >
        <HelpCategory
          v-for="(category, index) in helpMenu"
          :category="category"
          :isFirst="index == 0"
        />
      </div>
    </sl-dropdown>
  </div>
</template>

<script setup lang="ts">
import { useHelpMenu } from "@/composables/useHelpMenu";
import { PhQuestion } from "@phosphor-icons/vue";
import HelpCategory from "./HelpCategory.vue";
import { provide, useTemplateRef } from "vue";
import { helpMenuFunctionKey } from "@/type/menu";
import { SlDropdown } from "@shoelace-style/shoelace";
import Trigger from "./Trigger.vue";
import { useTeamBrain } from "@/composables/useTeamBrain";

const { helpMenu } = useHelpMenu();

const dropdown = useTemplateRef<SlDropdown>("dropdownMenu");

const { activate } = useTeamBrain();

provide(helpMenuFunctionKey, {
  teambrain: () => {
    dropdown.value?.hide();
    activate();
  },
});
</script>
