<script setup lang="ts">
import { onBeforeMount } from "vue";
onBeforeMount(() => {
  const header = document.querySelector("its-header");
  if (header) {
    header.setAttribute("baseurl", import.meta.env.VITE_PLATEFORME_BASE);
  }
});
</script>

<template></template>
