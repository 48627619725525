import { useEnv } from "./useEnv";
import { computed } from "vue";

export function useLinks() {
  const { isPending, isError, error, data } = useEnv();

  const links = computed(() => data.value?.links);

  return {
    links,
    isPending,
    isError,
    error,
  };
}
